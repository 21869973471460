/* eslint-disable no-unused-vars */
import React from 'react';
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import styled from 'styled-components';
import { IconButton, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { FadeInAnimation } from '../../common-components';
import PortfolioAgentCard from '../portfolio-agent-card.component';
import useResponsive from '../../../helpers/responsive';

const CustomButtonBack = React.forwardRef((props, ref) => (
    <IconButton component={ButtonBack} ref={ref} {...props} />
));

CustomButtonBack.displayName = 'CustomButtonBack';

const CustomButtonNext = React.forwardRef((props, ref) => (
    <IconButton component={ButtonNext} ref={ref} {...props} />
));

CustomButtonNext.displayName = 'CustomButtonNext';

const PortfolioAgentCarousel = ({ portfolio, allAgents }) => {
    const {
        isMobile,
        isTablet,
        isSmallTablet,
        isLargeTablet,
        isVerySmallMobile,
        isSmallMobile,
        isSmallBreakpoint,
    } = useResponsive();
    const portfolioId = _.get(portfolio, 'id');
    const numberOfSlides = _.size(allAgents);

    const renderCarouselItem = () => {
        return _.map(allAgents, (agent, index) => {
            const agentName = _.get(agent, 'firstName');
            const agentSurname = _.get(agent, 'lastName');
            const email = _.get(agent, 'email');
            const mobileNumber = _.get(agent, 'mobileNumber');
            const officeNumber = _.get(agent, 'officeNumber');
            const agentImgSrc = _.isEmpty(_.get(agent, 'images'))
                ? '/assets/images/avatar.png'
                : _.get(agent, 'images[0]');

            return (
                <SlideWrapper key={index}>
                    <FadeInAnimation>
                        <StyledSlide
                            $isSmallMobile={isSmallMobile}
                            $isMobile={isMobile}
                            $isTablet={isTablet}
                            $isSmallTablet={isSmallTablet}
                            $isLargeTablet={isLargeTablet}
                            $isVerySmallMobile={isVerySmallMobile}
                            $isSmallBreakpoint={isSmallBreakpoint}
                        >
                            <PortfolioAgentCard
                                agentName={agentName}
                                agentSurname={agentSurname}
                                email={email}
                                mobileNumber={mobileNumber}
                                officeNumber={officeNumber}
                                agentImgSrc={agentImgSrc}
                            />
                        </StyledSlide>
                    </FadeInAnimation>
                </SlideWrapper>
            );
        });
    };

    const renderBackButton = (right) => {
        return (
            <StyledCustomButtonBack
                id="city-carousel-back"
                key="back"
                aria-label="Back"
                right={right}
            >
                <StyledArrowBackRoundedIcon />
            </StyledCustomButtonBack>
        );
    };

    const renderNextButton = (right) => {
        return (
            <StyledCustomButtonNext
                id="city-carousel-next"
                key="next"
                aria-label="Next"
                right={right}
            >
                <StyledArrowForwardRoundedIcon />
            </StyledCustomButtonNext>
        );
    };

    const renderCarousel = () => {
        const nextButtonRight = 9;
        const backButtonRight = 11;

        let slideCount = 2.6;

        if (isTablet) {
            slideCount = 2.1;
        }
        if (isSmallTablet) {
            slideCount = 1.6;
        }
        if (isMobile) {
            slideCount = 1.1;
        }
        if (isVerySmallMobile) {
            slideCount = 1;
        }

        return (
            <div>
                <StyledCarouselProvider
                    $isMobile={isMobile}
                    id="apartment-types-carousel"
                    naturalSlideWidth={1}
                    naturalSlideHeight={0.6}
                    totalSlides={numberOfSlides}
                    visibleSlides={slideCount}
                    orientation="horizontal"
                    className="carousel-wrapper"
                    dragEnabled={isMobile || isTablet}
                    touchEnabled={isMobile || isTablet}
                    infinite
                >
                    <Slider classNameTray="slider-tray" aria-label="building-carousel">
                        {renderCarouselItem()}
                    </Slider>
                    <ButtonContainer>
                        {renderBackButton(backButtonRight)}
                        {renderNextButton(nextButtonRight)}
                    </ButtonContainer>
                </StyledCarouselProvider>
            </div>
        );
    };

    return <>{renderCarousel()}</>;
};

const StyledCustomButtonBack = styled(CustomButtonBack)`
    right: ${(props) => props.right}%;
    margin-top: 20px;
`;

const StyledCustomButtonNext = styled(CustomButtonNext)`
    border: 2px solid ${(props) => props.theme.carouselButtonBorder};
    border-radius: 50%;
    margin-top: 20px;
    right: ${(props) => props.right}%;
`;

const StyledArrowBackRoundedIcon = styled(ArrowBackRoundedIcon)`
    font-size: 32px;
    color: ${(props) => props.theme.carouselButtonBorder};
`;

const StyledArrowForwardRoundedIcon = styled(ArrowForwardRoundedIcon)`
    font-size: 32px;
    color: ${(props) => props.theme.paragraphDark};
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const SlideWrapper = styled.div`
    .focusRing___1airF.carousel__slide-focus-ring {
        outline: none !important;
        pointer-events: none;
    }
`;

const StyledCarouselProvider = styled(CarouselProvider)`
    margin-bottom: 80px;
`;

const StyledSlide = styled(Slide)`
    margin-bottom: ${({
        $isLargeTablet,
        $isTablet,
        $isSmallTablet,
        $isMobile,
        $isVerySmallMobile,
        $isSmallBreakpoint,
        $isSmallMobile,
    }) =>
        $isVerySmallMobile
            ? 150
            : $isSmallMobile
            ? 100
            : $isSmallBreakpoint
            ? 30
            : $isMobile
            ? 0
            : $isSmallTablet
            ? 60
            : $isTablet
            ? 90
            : $isLargeTablet
            ? 110
            : 70}px;
`;

export default PortfolioAgentCarousel;

PortfolioAgentCarousel.propTypes = {
    portfolio: PropTypes.object,
    allAgents: PropTypes.array,

    getAgentsDetailsByPortfolioId: PropTypes.func,
};
