import styled from 'styled-components';

import { baseColors } from '../../../utils/theme';

export const DummyHeaderOne = styled.p`
    font-family: 'Josefin Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 76.29px;
    line-height: 92.5%;
    letter-spacing: -0.05em;
    text-transform: uppercase;
    color: ${baseColors.white};
    margin-top: 4px;
    margin-bottom: 4px;
    @media only screen and (max-width: 1100px) {
        font-size: 47.8px;
    }
    @media only screen and (max-width: 700px) {
        font-size: 41.8px;
    }
`;

export const DummyHeaderTwo = styled.p`
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 61px;
    line-height: 110%;
    letter-spacing: 0.04em;
    color: ${baseColors.white};
    margin-top: 4px;
    margin-bottom: 4px;
    @media only screen and (max-width: 1100px) {
        font-size: 39.81px;
    }
    @media only screen and (max-width: 700px) {
        font-size: 29px;
    }
`;

export const DummyHeaderThree = styled.p`
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 39px;
    line-height: 110%;
    letter-spacing: 0.04em;
    color: ${baseColors.white};
    margin-top: 4px;
    margin-bottom: 4px;
    @media only screen and (max-width: 1100px) {
        font-size: 27.65px;
    }
    @media only screen and (max-width: 700px) {
        font-size: 20.16px;
    }
`;
