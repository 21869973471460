import _ from 'lodash';
import VisibilitySensor from 'react-visibility-sensor';
import React, { useState } from 'react';

import styled from 'styled-components';
import { FadeInAnimation } from '../components/common-components';
import { DummyHeaderOne } from '../components/dummy-headers/dummy-headers.component';

export function parseTitle(text, id, isMainHeaderOne, noFade) {
    const [isTextVisible, setTextVisible] = useState(false);

    const regex = {
        paragraph: /(?:\r\n){2,}/g,
        emphasize: /(_.*?_)/g,
    };

    let paragraph = _.split(text, regex.paragraph) || [];

    paragraph = paragraph.map((item, i) => {
        if (isMainHeaderOne) {
            return (
                <StyledH1 key={i} id={id}>
                    {item.split(regex.emphasize).map((str) => {
                        const parsed =
                            str.search(regex.emphasize) !== -1 ? (
                                <HeadingSpan isVisible={isTextVisible} key={i}>
                                    {str.substr(1, str.length - 2)}
                                </HeadingSpan>
                            ) : (
                                str
                            );
                        return parsed;
                    })}
                </StyledH1>
            );
        }
        return (
            <DummyHeaderOneStyled key={i} id={id}>
                {item.split(regex.emphasize).map((str) => {
                    const parsed =
                        str.search(regex.emphasize) !== -1 ? (
                            <HeadingSpan isVisible={isTextVisible} key={i}>
                                {str.substr(1, str.length - 2)}
                            </HeadingSpan>
                        ) : (
                            str
                        );
                    return parsed;
                })}
            </DummyHeaderOneStyled>
        );
    });

    const renderHeader = () => {
        if (noFade) {
            return <>{paragraph}</>;
        }
        return <FadeInAnimation>{paragraph}</FadeInAnimation>;
    };

    return (
        <VisibilitySensor
            onChange={(isVisible) => {
                setTextVisible(isVisible);
            }}
            delayedCall
        >
            <>{renderHeader()}</>
        </VisibilitySensor>
    );
}

const HeadingSpan = styled.span`
    transition: 2.5s;
    background-size: ${(props) => (props.isVisible ? '100% 30%' : '0% 30%')};
    transition-delay: ${(props) => (props.isVisible ? '0.5s' : '3600s')};
    background-image: url('/assets/images/heading_line.png');
    transition-timing-function: cubic-bezier(0.17, 0.67, 0.57, 0.96);
    background-repeat: no-repeat;
    background-position: left bottom;
    color: white;
`;

const StyledH1 = styled.h1`
    margin: 0px;
`;

const DummyHeaderOneStyled = styled(DummyHeaderOne)`
    margin: 0px;
`;
