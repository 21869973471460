/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Container } from '@material-ui/core';
import { Formik, Form, Field as FormikField } from 'formik';
import { TextField } from 'formik-material-ui';
import styled from 'styled-components';
import * as Yup from 'yup';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Phone, PhoneIphone, MailOutline } from '@material-ui/icons';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Button, FadeInAnimation, Dropdown, Image } from '../../components/common-components';
import useResponsive from '../../helpers/responsive';
import { submitPortfolioInquiry } from '../../common-reducers/portfolio-reducer/portfolio.actions';
import { roomTypesSelector } from '../../common-reducers/room-types-reducer/room-types.reducer';

const agentContactFormInitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    roomType: null,
};

const agentContactFormValidationSchema = Yup.object({
    firstName: Yup.string('').required('First name is required'),
    lastName: Yup.string('').required('Last name is required'),
    email: Yup.string('').email('Enter a valid email').required('Email is required'),
    phone: Yup.string('')
        .matches(/^[0-9,+]*$/, 'Phone number is invalid')
        .min(9, 'Phone number is too short (minimum 9)')
        .max(15, 'Phone number is too long (maximum 15)')
        .required('Phone number is required'),
    message: Yup.string('').required('Message is required'),
});

const PortfolioFooter = ({ portfolio }) => {
    const { roomTypes } = useSelector(roomTypesSelector);
    const [submitError, setSubmitError] = useState(null);
    const [successfullySubmitted, setSuccessfullySubmitted] = useState(null);
    const [roomTypeOptions, setRoomTypesOptions] = useState(null);
    const [roomType, setRoomTypes] = useState();
    const { isMobile, isVerySmallMobile, isTablet, isSmallBreakpoint } = useResponsive();
    const mobileNumber = _.get(portfolio, 'mobileNumber');
    const officeNumber = _.get(portfolio, 'officeNumber');
    const emailAddress = _.get(portfolio, 'emailAddress');
    const physicalAddress = _.get(portfolio, 'physicalAddress');
    const postalAddress = _.get(portfolio, 'postalAddress');
    const portfolioId = _.get(portfolio, 'id');

    useEffect(() => {
        const _roomTypesOptions = [];
        _.filter(roomTypes, (roomType) => {
            if (
                _.get(roomType, 'description') &&
                _.get(roomType, 'buildingName') &&
                !_.includes(roomType.id, '___female') &&
                _.get(roomType, 'portfolioId') === portfolioId
            ) {
                const obj = {
                    label: `${roomType.description}, ${roomType.buildingName}`,
                    value: roomType.id,
                    id: `${roomType.description}, ${roomType.buildingName}`,
                };
                _roomTypesOptions.push(obj);
            }
        });

        _roomTypesOptions.push({
            label: 'Not Sure',
            value: 'NOT_SURE',
            id: 'not_sure-select',
        });

        setRoomTypesOptions(_roomTypesOptions);
    }, [roomTypes]);

    const onRoomTypeChanged = (_roomType, event, formikOnChange) => {
        setRoomTypes(_roomType);
        formikOnChange(event);
    };

    const onSubmit = async (
        { firstName, lastName, email, phone, message, roomType },
        { setSubmitting },
    ) => {
        setSubmitting(true);

        try {
            await submitPortfolioInquiry(
                portfolioId,
                roomType === 'NOT_SURE' ? null : roomType,
                firstName,
                lastName,
                email,
                phone,
                message,
            );

            setSubmitError(null);
            setSuccessfullySubmitted(true);
        } catch (error) {
            setSubmitError(error.message);
            setSuccessfullySubmitted(false);
        } finally {
            setSubmitting(false);
        }
    };

    const currentYear = new Date().getFullYear();
    const footerTitle = !successfullySubmitted ? 'Get in Touch' : 'Thanks for your enquiry';

    const footerSubtitle = !successfullySubmitted
        ? [
              "What's new? What's hot?",
            <br key={1} />,
              'Got any questions or want to book a viewing?',
          ]
        : `We have received your enquiry and someone from ${_.get(
              portfolio,
              'name',
          )} will be in touch `;

    const renderDivider = () => {
        if (!postalAddress && !physicalAddress) {
            return null;
        }

        return <Divider />;
    };

    const renderFirstTwoButtons = () => {
        return (
            <>
                <a
                    aria-label="google play"
                    rel="noreferrer"
                    href="//play.google.com/store/apps/details?id=za.co.homii.mobile&hl=en"
                    target="_blank"
                >
                    <div className="app-download-buttons">
                        <Image
                            src="/assets/images/google_play.png"
                            alt="Google Play"
                            width={140}
                            height={48}
                        />
                    </div>
                </a>

                <a
                    aria-label="apple store"
                    href="//apps.apple.com/za/app/homii-lifestyle/id1478894335"
                    rel="noreferrer"
                    target="_blank"
                >
                    <div className="app-download-buttons">
                        <Image
                            src="/assets/images/apple.png"
                            alt="Play Store"
                            width={140}
                            height={48}
                        />
                    </div>
                </a>
            </>
        );
    };

    return (
        <FadeInAnimation>
            <FooterContainer $isMobile={isMobile}>
                <StyledContainer maxWidth="lg" $isMobile={isMobile}>
                    <StyledGrid container spacing={3} $isMobile={isMobile}>
                        <Grid item xs={12} sm={12} md={6}>
                            <StyledHeader4 marginBottom={16}>{footerTitle}</StyledHeader4>
                            <p className="footer-light-text">{footerSubtitle}</p>

                            <Formik
                                initialValues={agentContactFormInitialValues}
                                validationSchema={agentContactFormValidationSchema}
                                onSubmit={onSubmit}
                                validateOnBlur
                                validateOnChange={false}
                            >
                                {({
                                    submitForm,
                                    isSubmitting,
                                    touched,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                }) => (
                                    <Form>
                                        {!successfullySubmitted && (
                                            <>
                                                <NameInputContainer $isMobile={isMobile}>
                                                    <FirstNameField
                                                        $isMobile={isMobile}
                                                        component={TextField}
                                                        required
                                                        name="firstName"
                                                        id="firstName"
                                                        type="text"
                                                        label="Your Name"
                                                        variant="outlined"
                                                        color="secondary"
                                                        helperText={
                                                            touched.firstName
                                                                ? errors.firstName
                                                                : ''
                                                        }
                                                        error={
                                                            touched.firstName && !!errors.firstName
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        fullWidth
                                                    />
                                                    <LastNameField
                                                        $isMobile={isMobile}
                                                        component={TextField}
                                                        required
                                                        name="lastName"
                                                        id="lastName"
                                                        type="text"
                                                        label="Your Surname"
                                                        variant="outlined"
                                                        color="secondary"
                                                        helperText={
                                                            touched.lastName ? errors.lastName : ''
                                                        }
                                                        error={
                                                            touched.lastName && !!errors.lastName
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        fullWidth
                                                    />
                                                </NameInputContainer>
                                                <StyledField
                                                    component={TextField}
                                                    required
                                                    name="email"
                                                    id="email"
                                                    type="email"
                                                    label="Email"
                                                    variant="outlined"
                                                    color="secondary"
                                                    helperText={touched.email ? errors.email : ''}
                                                    error={touched.email && !!errors.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                />
                                                <StyledField
                                                    component={TextField}
                                                    required
                                                    name="phone"
                                                    id="phone"
                                                    type="tel"
                                                    label="Phone"
                                                    variant="outlined"
                                                    color="secondary"
                                                    helperText={touched.phone ? errors.phone : ''}
                                                    error={touched.phone && !!errors.phone}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                />
                                                {roomTypeOptions && (
                                                    <DropdownContainer>
                                                        <Dropdown
                                                            placeholder="Room Type"
                                                            selectId="roomtypes_dropdown"
                                                            options={roomTypeOptions}
                                                            onChange={(_roomType, event) => {
                                                                onRoomTypeChanged(
                                                                    _roomType,
                                                                    event,
                                                                    handleChange,
                                                                );
                                                            }}
                                                            defaultValue={roomType}
                                                            name="roomType"
                                                            helperText={
                                                                touched.roomType
                                                                    ? errors.roomType
                                                                    : ''
                                                            }
                                                            error={
                                                                touched.roomType &&
                                                                !!errors.roomType
                                                            }
                                                            handleBlur={handleBlur}
                                                        />
                                                    </DropdownContainer>
                                                )}
                                                <StyledField
                                                    component={TextField}
                                                    required
                                                    name="message"
                                                    id="message"
                                                    multiline
                                                    type="text"
                                                    label="Message"
                                                    variant="outlined"
                                                    color="secondary"
                                                    helperText={
                                                        touched.message ? errors.message : ''
                                                    }
                                                    error={touched.message && !!errors.message}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                />
                                                <br />
                                                <FormHelperText error>{submitError}</FormHelperText>
                                                <Button
                                                    variant="contained"
                                                    className="green-button"
                                                    id="footer-submit-button"
                                                    isLoading={isSubmitting}
                                                    onClick={submitForm}
                                                >
                                                    Submit
                                                </Button>
                                            </>
                                        )}
                                    </Form>
                                )}
                            </Formik>
                        </Grid>

                        <ContactDetailsGrid
                            $isTablet={isTablet}
                            container
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            className="footer-links"
                        >
                            {physicalAddress && (
                                <Grid item xs={12} sm={6}>
                                    <StyledHeader5 marginBottom={20}>
                                        Physical Address
                                    </StyledHeader5>
                                    <AddressText>
                                        {physicalAddress.addressLine1}, <br />
                                        {physicalAddress.addressLine2}, <br />
                                        {physicalAddress.postalCode}
                                    </AddressText>
                                    {isSmallBreakpoint && <Divider />}
                                </Grid>
                            )}
                            {postalAddress && (
                                <Grid item xs={12} sm={6}>
                                    <StyledHeader5 marginBottom={20}>Postal Address</StyledHeader5>
                                    <AddressText>
                                        {postalAddress.addressLine1}, <br />
                                        {postalAddress.addressLine2}, <br />
                                        {postalAddress.postalCode}
                                    </AddressText>
                                </Grid>
                            )}

                            {renderDivider()}

                            <Grid item xs={12} sm={6}>
                                <StyledHeader5 marginBottom={20}>Contact Us</StyledHeader5>
                                {mobileNumber && (
                                    <IconContainer>
                                        <PhoneIcon />
                                        <ContactText>{mobileNumber}</ContactText>
                                    </IconContainer>
                                )}

                                {officeNumber && (
                                    <IconContainer>
                                        <PhoneIphoneIcon />
                                        <ContactText>{officeNumber}</ContactText>
                                    </IconContainer>
                                )}

                                {emailAddress && (
                                    <IconContainer>
                                        <MailOutlineIcon />
                                        <ContactText>{emailAddress}</ContactText>
                                    </IconContainer>
                                )}

                                {isMobile && <Divider />}
                            </Grid>
                        </ContactDetailsGrid>
                    </StyledGrid>

                    <StyledGrid container spacing={3} $bottom $isMobile={isMobile}>
                        <Grid item md={12} xs={12} sm={6}>
                            <StyledHeader5 marginBottom={30}>Get the App</StyledHeader5>
                            <AppButtonsContainer $isMobile={isMobile}>
                                {isVerySmallMobile && renderFirstTwoButtons()}
                                {!isVerySmallMobile && (
                                    <RowButtons>{renderFirstTwoButtons()}</RowButtons>
                                )}

                                <a
                                    aria-label="huawei appgallery"
                                    href="//appgallery.huawei.com/#/app/C102716343"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <div className="app-download-buttons">
                                        <Image
                                            src="/assets/images/huawei.png"
                                            alt="Huawei Store"
                                            width={140}
                                            height={48}
                                        />
                                    </div>
                                </a>
                            </AppButtonsContainer>
                        </Grid>
                    </StyledGrid>

                    <CopyRightGrid item xs={12}>
                        <CopyRightText variant="subheading" component="span" $isMobile={isMobile}>
                            © Copyright HOMii {currentYear}
                        </CopyRightText>
                        <CopyRightText
                            variant="subheading"
                            component="span"
                            $isMobile={isMobile}
                            justify
                        >
                            All rights reserved.
                        </CopyRightText>
                    </CopyRightGrid>
                </StyledContainer>
            </FooterContainer>
        </FadeInAnimation>
    );
};

export default PortfolioFooter;

PortfolioFooter.propTypes = {
    portfolio: PropTypes.object,
};

const StyledHeader4 = styled.h4`
    margin-bottom: ${(props) => props.marginBottom}px;
    width: 80%;
`;

const StyledHeader5 = styled.h5`
    margin-bottom: ${(props) => props.marginBottom}px;
`;

const AppButtonsContainer = styled.div`
    display: flex;
    flex-direction: ${(props) => (props.$isMobile ? 'column' : 'row')};
    align-items: center;
`;

const FooterContainer = styled.div`
    margin-top: ${(props) => (props.$isMobile ? '0px' : '10px')};
    border-top: ${(props) => `solid 2px ${props.theme.darkerWhiteout}`};
    overflow-x: hidden;
`;

const StyledContainer = styled(Container)`
    padding-top: ${(props) => (props.$isMobile ? '50px' : '100px')};
    padding-bottom: ${(props) => (props.$isMobile ? '30px' : '50px')};
    border-color: ${(props) => props.theme.darkerWhiteout};
`;

const StyledGrid = styled(Grid)`
    margin-bottom: ${(props) =>
        props.$bottom && props.$isMobile
            ? '20px'
            : !props.$bottom && props.$isMobile
            ? '10px'
            : '60px'};
`;

const StyledField = styled(FormikField)`
    width: ${(props) => (props.$isMobile ? '100%' : '84%')};
    margin-bottom: 18px;
`;

const RowButtons = styled.div`
    display: flex;
    justify-content: center;
`;

const CopyRightText = styled.p`
    display: flex;
    color: ${(props) => props.theme.paragraphMedium};
    width: 100%;
    font-size: ${(props) => (props.$isMobile ? '12px' : '14px')};
    justify-content: ${(props) => (props.justify ? 'flex-end' : null)};
`;

const AddressText = styled.h6`
    color: ${(props) => props.theme.paragraphMedium};
    width: 80%;
`;

const ContactText = styled.h6`
    color: ${(props) => props.theme.paragraphMedium};
`;

const CopyRightGrid = styled(Grid)`
    padding: 8px 16px 8px 16px;
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    padding-left: 0px;
`;

const IconContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
`;

const PhoneIcon = styled(Phone)`
    color: ${(props) => props.theme.primaryLight};
    margin-right: 10px;
`;

const PhoneIphoneIcon = styled(PhoneIphone)`
    color: ${(props) => props.theme.primaryLight};
    margin-right: 10px;
`;

const MailOutlineIcon = styled(MailOutline)`
    color: ${(props) => props.theme.primaryLight};
    margin-right: 10px;
`;

const NameInputContainer = styled.div`
    display: ${(props) => !props.$isMobile && 'row'};
    flex-direction: ${(props) => !props.$isMobile && 'row'};
    margin-top: 18px;
    margin-bottom: 18px;
`;

const FirstNameField = styled(FormikField)`
    margin-right: 12px;
    margin-bottom: ${(props) => props.$isMobile && '18px'};
    width: ${(props) => (props.$isMobile ? '84%' : '40%')};
`;

const LastNameField = styled(FormikField)`
    margin-left: ${(props) => !props.$isMobile && '12px'};
    width: ${(props) => (props.$isMobile ? '84%' : '40%')};
`;

const DropdownContainer = styled.div`
    margin-bottom: 18px;
    width: 84%;
`;

const Divider = styled.div`
    background-color: #f4f4f4;
    width: 100%;
    height: 1px;
    margin-top: 30px;
    margin-bottom: 30px;
`;

const ContactDetailsGrid = styled(Grid)`
    margin-top: ${(props) => !props.$isTablet && 100}px;
`;
