import React, { useState, useEffect } from 'react';
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import { useSelector } from 'react-redux';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import styled from 'styled-components';
import Router from 'next/router';
import { IconButton } from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import getSlug from 'speakingurl';

import { FadeInAnimation, Image } from '../../components/common-components';
import { theme } from '../../../utils/theme';
import useResponsive from '../../helpers/responsive';
import { citySelector } from '../../common-reducers/city-reducer/city.reducer';
import { roomTypesSelector } from '../../common-reducers/room-types-reducer/room-types.reducer';

const CustomButtonBack = React.forwardRef((props, ref) => (
    <IconButton component={ButtonBack} ref={ref} {...props} />
));

CustomButtonBack.displayName = 'CustomButtonBack';

const CustomButtonNext = React.forwardRef((props, ref) => (
    <IconButton component={ButtonNext} ref={ref} {...props} />
));

CustomButtonNext.displayName = 'CustomButtonNext';

const ApartmentTypesCarousel = ({ portfolioId }) => {
    const { isMobile, isTablet } = useResponsive();

    const { selectedCity } = useSelector(citySelector);
    const { roomTypes } = useSelector(roomTypesSelector);
    const [roomTypeCategories, setRoomTypeCategories] = useState(null);
    const [numberOfSlides, setNumberOfSlides] = useState(0);

    useEffect(() => {
        if (portfolioId && roomTypes) {
            const filteredRoomTypes = _.filter(roomTypes, (roomType) => {
                if (_.get(roomType, 'portfolioId') === portfolioId) {
                    return roomType;
                }
                return null;
            });

            const rooms = _.map(filteredRoomTypes, (roomType) => {
                const category = _.get(roomType, 'category');
                const numberAvailable = _.get(roomType, 'numberAvailable', 0);
                const numberAvailableForMales = _.get(roomType, 'numberAvailableForMales', 0);
                const numberAvailableForFemales = _.get(roomType, 'numberAvailableForFemales', 0);
                const totalNumberAvailable =
                    numberAvailable + numberAvailableForMales + numberAvailableForFemales;

                return { category, totalNumberAvailable };
            });

            const uniqueRooms = _.groupBy(rooms, (room) => room.category);

            const result = Object.keys(uniqueRooms).map((key) => {
                const first = uniqueRooms[key][0];
                return {
                    ...first,
                    totalNumberAvailable: _.sumBy(uniqueRooms[key], (i) => i.totalNumberAvailable),
                };
            });

            setRoomTypeCategories(result);
            setNumberOfSlides(_.size(result));
        }
    }, [portfolioId, roomTypes]);

    const renderCategories = () => {
        return _.map(roomTypeCategories, (cat) => {
            return renderCarouselItem(getSlug(cat.category), cat.totalNumberAvailable);
        });
    };

    const renderRoomSlideText = (type, numberAvailable) => {
        let title = '';
        let subtext =
            numberAvailable === 1
                ? `${numberAvailable} Space Available`
                : `${numberAvailable} Spaces Available`;
        switch (type) {
            case 'one-bedroom':
                title = 'One Bedroom';
                break;
            case 'two-bedroom':
                title = 'Two Bedroom';
                break;
            case 'shared-room':
                title = 'Shared Room';
                break;
            case 'private-room':
                title = 'Private Room';
                break;
            case 'bachelor':
                title = 'Bachelor';
                break;
            case 'daily':
                title = 'Daily';
                break;
            default:
                title = '';
        }

        return (
            <>
                <StyledParagraph
                    className="city-name"
                    weight="bold"
                    color={theme.white}
                    marginBottom={5}
                >
                    {title}
                </StyledParagraph>
                <h6>{subtext}</h6>
            </>
        );
    };

    const onCategoryClick = (type) => {
        Router.push(
            `/bookings?city=${_.get(
                selectedCity,
                'name',
            )}&category=${type}&portfolioId=${portfolioId}`,
        ).then(() => window.scrollTo(0, 0));
    };

    const renderCarouselItem = (type, numberAvailable) => {
        return (
            <SlideWrapper key={type}>
                <Slide className="slide-container">
                    <StyledImage
                        src={`/assets/images/${type}.jpg`}
                        alt={type}
                        width="100%"
                        height="100%"
                        id={type}
                        onClick={() => onCategoryClick(type)}
                        animate
                        isContainerButton
                        containerWidth="90%"
                        containerHeight="70%"
                        isOldImage
                    />
                    {renderRoomSlideText(type, numberAvailable)}
                </Slide>
            </SlideWrapper>
        );
    };

    const renderBackButton = (right) => {
        return (
            <StyledCustomButtonBack
                id="city-carousel-back"
                key="back"
                aria-label="Back"
                right={right}
            >
                <StyledArrowBackRoundedIcon />
            </StyledCustomButtonBack>
        );
    };

    const renderNextButton = (right) => {
        return (
            <StyledCustomButtonNext
                id="city-carousel-next"
                key="next"
                aria-label="Next"
                right={right}
            >
                <StyledArrowForwardRoundedIcon />
            </StyledCustomButtonNext>
        );
    };

    const renderCarousel = () => {
        const nextButtonRight = 9;
        const backButtonRight = 11;

        let slideCount = numberOfSlides < 4 ? numberOfSlides : 3.5;

        if (isTablet) {
            slideCount = 2.5;
        }
        if (isMobile) {
            slideCount = 1.3;
        }
        return (
            <FadeInAnimation>
                <StyledCarouselProvider
                    $isMobile={isMobile}
                    id="apartment-types-carousel"
                    naturalSlideWidth={1}
                    naturalSlideHeight={0.95}
                    totalSlides={numberOfSlides}
                    visibleSlides={slideCount}
                    orientation="horizontal"
                    className="carousel-wrapper"
                    dragEnabled={isMobile || isTablet}
                    touchEnabled={isMobile || isTablet}
                    infinite
                >
                    <CarouselHeader>Apartment Types</CarouselHeader>
                    <SubTitle $isMobile={isMobile}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh eu rhoncus ac
                        mattis suspendisse et blandit dui aliq.
                    </SubTitle>

                    <Slider classNameTray="slider-tray" aria-label="building-carousel">
                        {renderCategories()}
                    </Slider>
                    <ButtonContainer>
                        {renderBackButton(backButtonRight)}
                        {renderNextButton(nextButtonRight)}
                    </ButtonContainer>
                </StyledCarouselProvider>
            </FadeInAnimation>
        );
    };

    return <>{roomTypes && renderCarousel()}</>;
};

ApartmentTypesCarousel.propTypes = {
    portfolioId: PropTypes.string,
};

export const StyledParagraph = styled.p`
    color: ${(props) => props.color};
    margin-top: ${(props) => props.marginTop}px;
    margin-bottom: ${(props) => props.marginBottom}px;
    font-weight: ${(props) => props.weight};
`;

const StyledCustomButtonBack = styled(CustomButtonBack)`
    right: ${(props) => props.right}%;
    margin-top: 20px;
`;

const StyledCustomButtonNext = styled(CustomButtonNext)`
    border: 2px solid ${(props) => props.theme.carouselButtonBorder};
    border-radius: 50%;
    margin-top: 20px;
    right: ${(props) => props.right}%;
`;

const StyledArrowBackRoundedIcon = styled(ArrowBackRoundedIcon)`
    font-size: 32px;
    color: ${(props) => props.theme.carouselButtonBorder};
`;

const StyledArrowForwardRoundedIcon = styled(ArrowForwardRoundedIcon)`
    font-size: 32px;
    color: ${(props) => props.theme.carouselButtonBorder};
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const SlideWrapper = styled.div`
    .slide-container:hover .city-name {
        color: ${(props) => props.theme.primaryLight};
    }

    .focusRing___1airF.carousel__slide-focus-ring {
        outline: none !important;
        pointer-events: none;
    }
`;

export const CarouselHeader = styled.h4`
    font-weight: bold;
    margin-bottom: 30px;
`;

const StyledImage = styled(Image)`
    border-radius: 5px;
    object-fit: cover;
`;

const StyledCarouselProvider = styled(CarouselProvider)`
    margin-bottom: 80px;
`;

const SubTitle = styled.p`
    color: ${(props) => props.theme.paragraphMedium};
    margin-bottom: 70px;
    width: ${({ $isMobile }) => ($isMobile ? '100%' : '60%')};
`;

export default ApartmentTypesCarousel;
