import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import React from 'react';
import Container from '@material-ui/core/Container';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

import SearchBar from '../search-bar/search-bar.container';
import MobileSearchBar from '../mobile-search-bar/mobile-search-bar.container';
import { parseTitle } from '../../helpers/title-parser';
import Image from '../common-components/image.component';
import { FadeInAnimation } from '../common-components';
import useResponsive from '../../helpers/responsive';

const useStyles = makeStyles((theme) => ({
    background: {
        [theme.breakpoints.between(960, 1320)]: {
            display: 'none',
        },
    },
}));

const PortfolioHeroSection = ({ portfolio }) => {
    const isMainHeaderOne = true;
    const { isMobile, isTablet } = useResponsive();
    const classes = useStyles();

    const name = _.get(portfolio, 'name');
    const details = _.get(portfolio, 'details');
    const title = `Find _Your_ Space with ${name}`;
    const subheading = 'Find your favourite space'; // Ask Cobus to add slogan section, default to this is company does not have slogan
    const hasLogo = _.has(portfolio, 'images[0]');

    const renderImageSectionDesktop = () => {
        if (isMobile || isTablet || !hasLogo) {
            return null;
        }

        return (
            <Image
                src={_.get(portfolio, 'images[0]')}
                alt={`${name}-logo`}
                width={400}
                height={200}
                priority
                isOldImage
            />
        );
    };

    const renderImageSectionMobile = () => {
        if ((!isMobile && !isTablet) || hasLogo) {
            return null;
        }

        return (
            <>
                <Background className={classes.background} />
                <ImageContainer>
                    <Image
                        src={_.get(portfolio, 'images[0]')}
                        alt={`${name}-logo`}
                        width={264}
                        height={132}
                        isOldImage
                    />
                </ImageContainer>
            </>
        );
    };

    const renderImageSection = () => {
        return (
            <>
                {renderImageSectionDesktop()}
                {renderImageSectionMobile()}
            </>
        );
    };

    return (
        <ContainerStyle maxWidth="lg" $isMobile={isMobile} hasLogo={hasLogo}>
            <Grid container spacing={0}>
                <HeadingGrid
                    item
                    lg={hasLogo ? 5 : 12}
                    md={hasLogo ? 6 : 12}
                    sm={12}
                    $isMobile={isMobile}
                >
                    <HeadingContainer>
                        {parseTitle(title, 'home_header', isMainHeaderOne)}
                    </HeadingContainer>

                    <FadeInAnimation>
                        <Subheading id="home_subheading" className="header-subheading">
                            {subheading}
                        </Subheading>

                        <Paragraph id="home_hero_paragraph" className="header-paragraph-text">
                            {details}
                        </Paragraph>
                    </FadeInAnimation>
                </HeadingGrid>

                <ImageGrid item lg={hasLogo ? 7 : 12} md={hasLogo ? 6 : 12} sm={12} xs={12}>
                    {renderImageSection()}
                </ImageGrid>
                {!isMobile && (
                    <LandscapeSearchBarContainer>
                        <SearchBarContainer>
                            <SearchBar type="Portfolio" portfolioId={_.get(portfolio, 'id')} />
                        </SearchBarContainer>
                    </LandscapeSearchBarContainer>
                )}
                {isMobile && (
                    <MobileSearchBarContainer>
                        <MobileSearchBar
                            isMobile={isMobile}
                            isTablet={isTablet}
                            type="Portfolio"
                            portfolioId={_.get(portfolio, 'id')}
                        />
                    </MobileSearchBarContainer>
                )}
            </Grid>
        </ContainerStyle>
    );
};

export default PortfolioHeroSection;

PortfolioHeroSection.propTypes = {
    portfolio: PropTypes.object,
};

const Subheading = styled.h4`
    margin-bottom: 20px;
    line-height: 120%;
`;

const Paragraph = styled.p`
    margin-top: 20px;
`;

const HeadingContainer = styled.div`
    width: 90%;
    margin-bottom: 40px;
`;

const ContainerStyle = styled(Container)`
    padding-bottom: ${(props) => (props.$isMobile ? 30 : 0)}px;
    padding-top: ${(props) => (props.$isMobile ? 30 : props.hasLogo ? 160 : 60)}px;
`;

const SearchBarContainer = styled.div`
    width: 100%;
    left: 0%;
    z-index: 2;
`;

const LandscapeSearchBarContainer = styled.div`
    position: relative;
    width: 100%;
`;

const HeadingGrid = styled(Grid)`
    padding-right: ${(props) => (props.$isMobile ? '5%' : '0%')};
    padding-top: ${(props) => (props.$isMobile ? '15%' : '0%')};
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: ${(props) => (props.$isMobile ? '30px' : '100px')};
`;

const MobileSearchBarContainer = styled.div`
    width: 100%;
`;

const ImageGrid = styled(Grid)`
    display: flex;
    justify-content: center;
`;

const Background = styled.div`
    position: absolute;
    width: 100%;
    height: 220px;
    z-index: 0;
    background: ${(props) => props.theme.backgroundColor};
`;

const ImageContainer = styled.div`
    padding-bottom: 80px;
`;
