/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Phone, PhoneIphone, MailOutline } from '@material-ui/icons';
import { Paper } from '@material-ui/core';

import useResponsive from '../../helpers/responsive';
import { Image } from '../common-components';

const PortfolioAgentCard = (props) => {
    const { isMobile, isTablet, isVerySmallMobile, isSmallMobile } = useResponsive();
    const { agentName, agentSurname, email, mobileNumber, officeNumber, agentImgSrc } = props;

    return (
        <StyledPaper $isMobile={isMobile}>
            <StyledImage
                src={agentImgSrc}
                alt="agent-details"
                width="43%"
                height={isMobile ? 170 : isTablet ? 210 : 250}
                id={email}
                isOldImage
            />

            <DetailsContainer>
                <AgentCardName>
                    {agentName} {agentSurname}
                </AgentCardName>
                <IconContainer>
                    <PhoneIcon />
                    <ContactText
                        $isVerySmallMobile={isVerySmallMobile}
                        $isSmallMobile={isSmallMobile}
                    >
                        {officeNumber}
                    </ContactText>
                </IconContainer>
                <IconContainer>
                    <PhoneIphoneIcon />
                    <ContactText
                        $isVerySmallMobile={isVerySmallMobile}
                        $isSmallMobile={isSmallMobile}
                    >
                        {mobileNumber}
                    </ContactText>
                </IconContainer>
                <IconContainer>
                    <MailOutlineIcon />
                    <ContactText
                        $isVerySmallMobile={isVerySmallMobile}
                        $isSmallMobile={isSmallMobile}
                    >
                        {email}
                    </ContactText>
                </IconContainer>
            </DetailsContainer>
        </StyledPaper>
    );
};

export default PortfolioAgentCard;

PortfolioAgentCard.propTypes = {
    portfolio: PropTypes.object,
    allAgents: PropTypes.array,

    getAgentsDetailsByPortfolioId: PropTypes.func,
};

export const StyledParagraph = styled.p`
    color: ${(props) => props.color};
    margin-top: ${(props) => props.marginTop}px;
    margin-bottom: ${(props) => props.marginBottom}px;
    font-weight: ${(props) => props.weight};
`;

const StyledImage = styled(Image)`
    border-radius: 5px 0px 0px 5px;
    object-fit: cover;
`;

const PhoneIcon = styled(Phone)`
    color: ${(props) => props.theme.primaryLight};
    margin-right: 10px;
`;

const PhoneIphoneIcon = styled(PhoneIphone)`
    color: ${(props) => props.theme.primaryLight};
    margin-right: 10px;
`;

const MailOutlineIcon = styled(MailOutline)`
    color: ${(props) => props.theme.primaryLight};
    margin-right: 10px;
`;

const IconContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
`;

const AgentCardName = styled.h5`
    margin-bottom: 15px;
`;

const ContactText = styled.h6`
    color: ${(props) => props.theme.paragraphMedium};
    width: ${({ $isVerySmallMobile, $isSmallMobile }) =>
        $isVerySmallMobile ? '50%' : $isSmallMobile ? '65%' : '90%'};
    word-wrap: break-word;
`;

const StyledPaper = styled(Paper)`
    width: ${($isMobile) => ($isMobile ? '95%' : '85%')};
    display: flex;
    margin-top: 70px;
    align-items: center;
    background-color: #1e252c;
`;

const DetailsContainer = styled.div`
    margin-left: 20px;
`;

PortfolioAgentCard.propTypes = {
    agentName: PropTypes.string,
    agentSurname: PropTypes.string,
    email: PropTypes.string,
    mobileNumber: PropTypes.string,
    officeNumber: PropTypes.string,
    agentImgSrc: PropTypes.string,
};
