/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';

import PortfolioAgentCarousel from './portfolio-agent-carousel/portfolio-agent-carousel.container';
import { CarouselContainer, FadeInAnimation } from '../common-components';
import useResponsive from '../../helpers/responsive';
import PortfolioAgentCard from './portfolio-agent-card.component';

const PortfolioAgent = ({ portfolio, getAgentsDetailsByPortfolioId, allAgents }) => {
    const { isMobile, isSmallTablet } = useResponsive();
    const [numberOfAgents, setNumberOfAgents] = useState(0);
    const portfolioId = _.get(portfolio, 'id');

    useEffect(() => {
        getAgentsDetailsByPortfolioId(portfolioId);
    }, [portfolio]);

    useEffect(() => {
        const agentCount = _.size(allAgents);
        setNumberOfAgents(agentCount);
    }, [allAgents]);

    const renderHeader = () => {
        return (
            <FadeInAnimation>
                <CarouselHeader>Meet our Agents</CarouselHeader>
                <SubTitle $isMobile={isMobile}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh eu rhoncus ac
                    mattis suspendisse et blandit dui aliq.
                </SubTitle>
            </FadeInAnimation>
        );
    };

    const renderCards = () => {
        return _.map(allAgents, (agent, index) => {
            const agentName = _.get(agent, 'firstName');
            const agentSurname = _.get(agent, 'lastName');
            const email = _.get(agent, 'email');
            const mobileNumber = _.get(agent, 'mobileNumber');
            const officeNumber = _.get(agent, 'officeNumber');
            const agentImgSrc = _.isEmpty(_.get(agent, 'images'))
                ? '/assets/images/avatar.png'
                : _.get(agent, 'images[0]');

            return (
                <CardContainer key={index}>
                    <CardWrapper $isSmallTablet={isSmallTablet} $isMobile={isMobile}>
                        <PortfolioAgentCard
                            agentName={agentName}
                            agentSurname={agentSurname}
                            email={email}
                            mobileNumber={mobileNumber}
                            officeNumber={officeNumber}
                            agentImgSrc={agentImgSrc}
                        />
                    </CardWrapper>
                </CardContainer>
            );
        });
    };

    if (numberOfAgents === 0) {
        return null;
    }

    if (numberOfAgents < 3) {
        return (
            <StyledContainer maxWidth="lg">
                {renderHeader()}
                {renderCards()}
            </StyledContainer>
        );
    }

    return (
        <>
            <CarouselContainer isBuilding>
                {renderHeader()}
                <PortfolioAgentCarousel portfolio={portfolio} />
            </CarouselContainer>
        </>
    );
};

export default PortfolioAgent;

PortfolioAgent.propTypes = {
    portfolio: PropTypes.object,
    allAgents: PropTypes.array,

    getAgentsDetailsByPortfolioId: PropTypes.func,
};

const StyledContainer = styled(Container)`
    margin-bottom: 70px;
`;

const CarouselHeader = styled.h4`
    font-weight: bold;
    margin-bottom: 30px;
`;

const SubTitle = styled.p`
    color: ${(props) => props.theme.paragraphMedium};
    width: ${({ $isMobile }) => ($isMobile ? '100%' : '60%')};
`;

const CardContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;

const CardWrapper = styled.div`
    width: ${({ $isSmallTablet, $isMobile }) =>
        $isMobile ? '100%' : $isSmallTablet ? '80%' : '60%'};
`;
