import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import useResponsive from '../../helpers/responsive';

import Image from '../common-components/image.component';
import { theme as themeColors } from '../../../utils/theme';
import { FadeInAnimation } from '../common-components';
import { DummyHeaderThree } from '../dummy-headers/dummy-headers.component';

const useStyles = makeStyles((theme) => ({
    absoluteImage: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    appImageGridContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
    },
    appImageContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'relative',
        width: '80%',

        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    gridContainer: {
        paddingTop: 40,
        paddingBottom: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '100%',
    },
    iconAndTextContainer: {
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid rgba(30, 37, 44, 0.1)',
        marginTop: 30,
        marginBottom: 60,
        paddingTop: 30,
    },
    darkIconAndTextContainer: {
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid #FFFFFF',
        marginTop: 30,
        marginBottom: 60,
        paddingTop: 30,
    },
    darkParagraphText: {
        color: themeColors.white,
    },
    caption: {
        width: '60%',
    },
    darkCaption: {
        color: themeColors.white,
        width: '60%',
    },
    mobileContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 70,
        paddingBottom: 30,
    },
    mobileTitle: {
        marginBottom: 20,
    },
}));

const MobileAppSection = (props) => {
    const { isMobile, isTablet } = useResponsive();
    const { isDark, paragraph, icon, iconText, primaryImgSrc, primaryImgAlt } = props;

    const classes = useStyles();

    const renderDesktop = () => {
        if (!isMobile) {
            return (
                <Grid className={classes.gridContainer} container spacing={3}>
                    <Grid item lg={5} md={6} sm={12}>
                        {isDark ? renderDarkTextSection() : renderTextSection()}
                        <DesktopAndTabletContainer isTablet={isTablet}>
                            <a
                                href="//play.google.com/store/apps/details?id=za.co.homii.mobile&hl=en"
                                rel="noreferrer"
                                target="_blank"
                                aria-label="google play"
                            >
                                <div className="app-download-buttons">
                                    <Image
                                        src="/assets/images/google_play.png"
                                        alt="Google Play"
                                        width={141}
                                        height={34}
                                    />
                                </div>
                            </a>
                            <a
                                href="//apps.apple.com/za/app/homii-lifestyle/id1478894335"
                                rel="noreferrer"
                                target="_blank"
                                aria-label="apple store"
                            >
                                <div className="app-download-buttons">
                                    <Image
                                        src="/assets/images/apple.png"
                                        alt="Play Store"
                                        width={141}
                                        height={34}
                                    />
                                </div>
                            </a>
                            <a
                                href="//appgallery.huawei.com/#/app/C102716343"
                                rel="noreferrer"
                                target="_blank"
                                aria-label="huawei appgallery"
                            >
                                <div className="app-download-buttons">
                                    <Image
                                        src="/assets/images/huawei.png"
                                        alt="Huawei Store"
                                        width={141}
                                        height={34}
                                    />
                                </div>
                            </a>
                        </DesktopAndTabletContainer>
                    </Grid>
                    <Grid item lg={7} md={6} sm={12} className={classes.appImageGridContainer}>
                        <div className={classes.appImageContainer}>
                            <Image
                                src="/assets/images/phone-min.png"
                                alt="Live the city vibe"
                                width={773}
                                height={739}
                            />
                        </div>
                    </Grid>
                </Grid>
            );
        }
        return null;
    };

    const renderMobile = () => {
        if (isMobile) {
            return (
                <div className={classes.mobileContainer}>
                    {isDark && <HeaderThreeDark>Download the app today</HeaderThreeDark>}
                    {!isDark && <HeaderThree>Download the app today</HeaderThree>}
                    <p className={isDark ? classes.darkParagraphText : null}>{paragraph}</p>
                    <MobileImageContainer>
                        <Image src={primaryImgSrc} alt={primaryImgAlt} width={446} height={605} />
                    </MobileImageContainer>

                    <div className={classes.gridContainer}>
                        <IconContainer width={48} height={48}>
                            <Icon src={icon} alt="" />
                        </IconContainer>
                        <h6 className={isDark ? classes.darkCaption : classes.caption}>
                            {iconText}
                        </h6>
                    </div>
                    <MobileAppButtonsContainer>
                        <MobileAppButtonsRow>
                            <a
                                href="//play.google.com/store/apps/details?id=za.co.homii.mobile&hl=en"
                                rel="noreferrer"
                                target="_blank"
                            >
                                <div className="app-download-buttons">
                                    <Image
                                        src="/assets/images/google_play.png"
                                        alt="Google Play"
                                        width={141}
                                        height={34}
                                    />
                                </div>
                            </a>

                            <a
                                href="//apps.apple.com/za/app/homii-lifestyle/id1478894335"
                                rel="noreferrer"
                                target="_blank"
                            >
                                <div className="app-download-buttons">
                                    <Image
                                        src="/assets/images/apple.png"
                                        alt="Play Store"
                                        width={141}
                                        height={34}
                                    />
                                </div>
                            </a>
                        </MobileAppButtonsRow>
                        <a
                            href="//appgallery.huawei.com/#/app/C102716343"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <div className="app-download-buttons">
                                <Image
                                    src="/assets/images/huawei.png"
                                    alt="Play Store"
                                    width={141}
                                    height={34}
                                />
                            </div>
                        </a>
                    </MobileAppButtonsContainer>
                </div>
            );
        }
        return null;
    };

    const renderTextSection = () => {
        return (
            <>
                <FadeInAnimation>
                    <div>
                        <HeaderThree isMobile={isMobile}>Download the app today</HeaderThree>
                    </div>
                    <p>{paragraph}</p>
                    <div className={classes.iconAndTextContainer}>
                        <IconContainer width={80} height={80}>
                            <img src={icon} alt="" />
                        </IconContainer>
                        <h6 className={classes.caption}>{iconText}</h6>
                    </div>
                </FadeInAnimation>
            </>
        );
    };

    const renderDarkTextSection = () => {
        return (
            <>
                <FadeInAnimation>
                    <div>
                        <HeaderThreeDark>Download the app today</HeaderThreeDark>
                        <p className={classes.darkParagraphText}>{paragraph}</p>
                        <div className={classes.darkIconAndTextContainer}>
                            <DarkIconContainer width={80} height={80}>
                                <Image src={icon} alt="" isOldImage />
                            </DarkIconContainer>
                            <h6 className={classes.darkCaption}>{iconText}</h6>
                        </div>
                    </div>
                </FadeInAnimation>
            </>
        );
    };

    return (
        <Container maxWidth="lg">
            {renderDesktop()}
            {renderMobile()}
        </Container>
    );
};

export default MobileAppSection;

MobileAppSection.propTypes = {
    isDark: PropTypes.bool,
    title: PropTypes.string,
    paragraph: PropTypes.string,
    icon: PropTypes.string,
    iconText: PropTypes.string,
    primaryImgSrc: PropTypes.string,
    primaryImgAlt: PropTypes.string,
    secondaryImgSrc: PropTypes.string,
    secondaryImgAlt: PropTypes.string,
};

const IconContainer = styled.div`
    display: flex;
    background-color: ${(props) => props.theme.paragraphMedium};
    min-width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    margin-right: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
`;

const DarkIconContainer = styled.div`
    display: flex;
    background-color: ${(props) => props.theme.paragraphMedium};
    min-width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    margin-right: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
`;

const DesktopAndTabletContainer = styled.div`
    display: flex;
    justify-content: ${(props) => (props.isTablet ? 'center' : 'flex-start')};
`;

const HeaderThree = styled(DummyHeaderThree)`
    margin-bottom: ${(props) => (props.isMobile ? 20 : 30)}px;
`;

const HeaderThreeDark = styled(HeaderThree)`
    color: white;
`;

const MobileImageContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const Icon = styled.img`
    object-fit: contain;
    height: 24px;
`;

const MobileAppButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    padding-bottom: 20px;
`;

const MobileAppButtonsRow = styled.div`
    display: flex;
    flex-direction: row;
`;
