import _ from 'lodash';

import { agentService, mediaService, eventService } from '../../services/homii-services';
import { setIsLoadingAction, setAgentDetailsAction, setAllAgentsActions } from './agent.reducer';

export function getAgentsDetailsByPortfolioId(portfolioId) {
    return async (dispatch) => {
        dispatch(setIsLoadingAction(true));
        return agentService
            .getAgentByPortfolioId(portfolioId)
            .then((agents) => {
                let agentsWithImages = [];
                _.map(agents, (agent) => {
                    const agentWithImages = mediaService.generateAgentImageUrls(agent);
                    agentsWithImages.push(agentWithImages);
                });

                dispatch(setAllAgentsActions(agentsWithImages));
            })
            .catch((error) => {
                throw Error(error.response.data.message);
            })
            .finally(() => {
                dispatch(setIsLoadingAction(false));
            });
    };
}

export function getAgentDetailsById(id) {
    return async (dispatch) => {
        dispatch(setIsLoadingAction(true));
        return agentService
            .getAgentById(id)
            .then((response) => {
                const agentWithImages = mediaService.generateAgentImageUrls(response);
                dispatch(setAgentDetailsAction(agentWithImages));
            })
            .catch((error) => {
                throw Error(error.response.data.message);
            })
            .finally(() => {
                dispatch(setIsLoadingAction(false));
            });
    };
}

export function submitAgentInquiry(
    agentId,
    roomTypeId,
    firstName,
    lastName,
    phoneNumber,
    email,
    inquiry,
) {
    try {
        agentService.submitAgentInquiry(
            agentId,
            roomTypeId,
            firstName,
            lastName,
            phoneNumber,
            email,
            inquiry,
        );
        eventService.logCustomEvent('successful_agent_inquiry', {
            description: 'Successfuly submitted an agent inquiry',
        });
    } catch (error) {
        throw Error(
            _.get(
                error,
                'response.data.message',
                'An error occurred while trying to submit your inquiry.',
            ),
        );
    }
}
