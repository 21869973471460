import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import getSlug from 'speakingurl';
import _ from 'lodash';
import getConfig from 'next/config';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import useResponsive from '../helpers/responsive';
import { cmsService, geolocationService } from '../services/homii-services';
import { CarouselContainer } from '../components/common-components/';
import { setGeoErrorAction } from '../common-reducers/geolocation-reducer/geolocation.reducer';
import {
    setSelectedCityById,
    setSelectedCityByClosestCoordinates,
    getAllCities,
    getAllCitiesByPortfolio,
} from '../common-reducers/city-reducer/city.actions';
import { getAllPortfolios } from '../common-reducers/portfolio-reducer/portfolio.actions';
import ErrorPage from './_error';
import PortfolioHeroSection from '../components/portfolio-components/portfolio-hero-section.component';
import LazyFeaturedSpaces from '../components/featured-spaces/lazy-featured-spaces.container';
import PortfolioFooter from '../components/portfolio-components/portfolio-footer.component';
import MobileAppSection from '../components/home-page-components/mobile-app-section.component';
import ApartmentTypesCarousel from '../components/portfolio-components/apartment-types-carousel.component';
import PortfolioNewlyListed from '../components/portfolio-components/portfolio-newly-listed.component';
import PortfolioAgent from '../components/portfolio-components/portfolio-agent.container';
import { getAllRoomTypesByPortfolioId } from '../common-reducers/room-types-reducer/room-types.action';
import { citySelector } from '../common-reducers/city-reducer/city.reducer';

const Portfolio = ({ butterData, query, allPortfolios, geolocatedData }) => {
    const { isMobile } = useResponsive();
    const portfolioName = _.get(query, 'portfolio-name');
    const [validPortfolio, setValidPortfolio] = useState(null);
    const [portfolioData, setPortfolioData] = useState(null);
    const dispatch = useDispatch();
    const { selectedCity } = useSelector(citySelector);

    const mobileAppTitle = _.get(butterData, 'acf.mobile_title');
    const mobileAppParagraph = _.get(butterData, 'acf.mobile_paragraph');
    const mobileAppIcon = _.get(butterData, 'acf.mobile_icon.url');
    const mobileAppIconText = _.get(butterData, 'acf.mobile_icon_text');
    const mobilePrimaryImgSrc = _.get(butterData, 'acf.mobile_primary_image.url');
    const mobilePrimaryImgAlt = _.get(butterData, 'acf.mobile_primary_image_alt');
    const mobileAppSecondaryImgSrc = _.get(butterData, 'acf.mobile_secondary_image.url');
    const mobileAppSecondaryImgAlt = _.get(butterData, 'acf.mobile_secondary_image_alt');

    useEffect(() => {
        checkForPathMatch();
    }, [query]);

    useEffect(() => {
        if (portfolioData) {
            dispatch(getAllRoomTypesByPortfolioId(_.get(portfolioData, 'id')));
        }
    }, [portfolioData]);

    const checkForPathMatch = () => {
        const matchingPortfolio = _.find(
            allPortfolios,
            (portfolio) => getSlug(portfolio.name) === portfolioName,
        );
        if (matchingPortfolio) {
            setValidPortfolio(true);
            setPortfolioData(matchingPortfolio);

            if (!selectedCity) {
                if (_.get(geolocatedData, 'latitude') && _.get(geolocatedData, 'longitude')) {
                    dispatch(
                        setSelectedCityByClosestCoordinates(
                            _.get(geolocatedData, 'latitude'),
                            _.get(geolocatedData, 'longitude'),
                            _.get(matchingPortfolio, 'id'),
                        ),
                    );
                } else {
                    dispatch(
                        setSelectedCityById(_.get(matchingPortfolio, 'physicalAddress.cityId')),
                    );
                }
            }

            dispatch(getAllCitiesByPortfolio(_.get(matchingPortfolio, 'id')));
        } else {
            setValidPortfolio(false);
        }
    };

    if (_.isNil(validPortfolio)) {
        return <div />;
    } else if (!validPortfolio) {
        return <ErrorPage statusCode={404} />;
    }

    return (
        <div>
            <PortfolioHeroSection portfolio={portfolioData} />
            {selectedCity && (
                <LazyFeaturedSpaces
                    title="Popular Spaces"
                    portfolioId={_.get(portfolioData, 'id')}
                />
            )}
            <PortfolioAgent portfolio={portfolioData} />
            <PortfolioNewlyListed portfolio={portfolioData} />
            <CarouselContainer isBuilding>
                <ApartmentTypesCarousel portfolioId={_.get(portfolioData, 'id')} />
            </CarouselContainer>

            <MobileAppSectionContainer>
                <MobileAppSection
                    isMobile={isMobile}
                    title={mobileAppTitle}
                    paragraph={mobileAppParagraph}
                    icon={mobileAppIcon}
                    iconText={mobileAppIconText}
                    primaryImgSrc={mobilePrimaryImgSrc}
                    primaryImgAlt={mobilePrimaryImgAlt}
                    secondaryImgSrc={mobileAppSecondaryImgSrc}
                    secondaryImgAlt={mobileAppSecondaryImgAlt}
                />
            </MobileAppSectionContainer>
            <PortfolioFooter portfolio={portfolioData} />
        </div>
    );
};

export default Portfolio;

Portfolio.getInitialProps = async (ctx, dispatch, getState) => {
    const { publicRuntimeConfig } = getConfig();
    const { query } = ctx;

    let geolocatedData = null;
    const butterData = await cmsService.RetrieveWordPressPage('portfolio');
    await dispatch(getAllPortfolios());
    const { allPortfolios } = getState().portfolioReducer;
    await dispatch(getAllCities());

    try {
        const clientIp = _.get(ctx, 'req')
            ? ctx.req.headers['x-azure-clientip'] ||
              ctx.req.headers['x-forwarded-for'] ||
              ctx.req.connection.remoteAddress
            : null;

        if (clientIp && publicRuntimeConfig.HOMII_FEATURE_PAID_GEO_SERVICE) {
            geolocatedData = await geolocationService.getGeolocationDetails(clientIp);
        }

        if (!geolocatedData) {
            geolocatedData = await geolocationService.getGeolocationDetailsFallback();
        }
    } catch (error) {
        dispatch(setGeoErrorAction(error));
    }

    return { butterData, query, allPortfolios, geolocatedData };
};

Portfolio.propTypes = {
    query: PropTypes.object,
    allPortfolios: PropTypes.array,
    butterData: PropTypes.object,
    geolocatedData: PropTypes.array,
};

const MobileAppSectionContainer = styled.div`
    background-color: transparent;
`;
