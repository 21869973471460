import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import PortfolioAgent from './portfolio-agent.component';
import { getAgentsDetailsByPortfolioId } from '../../common-reducers/agent-reducer/agent.actions';

function mapStateToProps({ agentReducer }) {
    return {
        allAgents: agentReducer.allAgents,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getAgentsDetailsByPortfolioId,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioAgent);
