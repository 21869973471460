import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Container, Grid } from '@material-ui/core';
import { Bathtub, KingBedOutlined, DriveEtaOutlined } from '@material-ui/icons';

import { citySelector } from '../../common-reducers/city-reducer/city.reducer';
import { roomTypesSelector } from '../../common-reducers/room-types-reducer/room-types.reducer';
import useResponsive from '../../helpers/responsive';

const MediaGallery = dynamic(import('../media-gallery/media-gallery.component'));

const PortfolioNewlyListed = ({ portfolio }) => {
    const { isMobile, isTablet } = useResponsive();
    const { selectedCity } = useSelector(citySelector);
    const portfolioId = _.get(portfolio, 'id');
    const { roomTypes } = useSelector(roomTypesSelector);
    const [newRoomTypes, setNewRoomTypes] = useState(null);

    useEffect(() => {
        if (roomTypes) {
            const filteredRoomTypes = _.filter(roomTypes, (roomType) => {
                if (_.get(roomType, 'acceptingProvisionalBookings')) {
                    return roomType;
                }
                return null;
            });

            setNewRoomTypes(filteredRoomTypes);
        }
    }, [roomTypes]);

    const renderIcons = () => {
        const size = !isMobile ? 'large' : 'medium';
        return (
            <Row $isMobile={isMobile}>
                <BathtubIcon fontSize={size} />
                <StyledNumber>{_.get(newRoomTypes[0], 'numberOfBathrooms')}</StyledNumber>
                <KingBedOutlinedIcon fontSize={size} />
                <StyledNumber>{_.get(newRoomTypes[0], 'numberOfBedrooms')}</StyledNumber>
                <DriveEtaOutlinedIcon fontSize={size} />
                <StyledNumber>{_.get(newRoomTypes[0], 'numberOfParkingBays') || 0}</StyledNumber>
            </Row>
        );
    };

    const renderMobileDetails = () => {
        if (!isMobile) {
            return null;
        }
        return (
            <>
                <PropertyNameMobile>{_.get(newRoomTypes[0], 'details')}</PropertyNameMobile>
                <PropertyDetails $isMobile={isMobile}>
                    {_.get(newRoomTypes[0], 'description')}
                </PropertyDetails>
                {renderIcons()}
            </>
        );
    };

    const renderDesktopDetails = () => {
        if (isMobile) {
            return null;
        }

        return (
            <>
                <PropertyName>
                    {_.get(newRoomTypes[0], 'description')}
                    {', '}
                    {_.get(newRoomTypes[0], 'buildingName')}
                </PropertyName>
                <Row>
                    <PropertyDetails>{_.get(newRoomTypes[0], 'details')}</PropertyDetails>
                    {renderIcons()}
                </Row>
            </>
        );
    };

    return (
        <>
            {newRoomTypes && !_.isEmpty(newRoomTypes) && (
                <Wrapper>
                    <Container maxWidth="lg">
                        <Grid container spacing={0}>
                            <HeaderGrid item lg={2} md={2} sm={12} xs={12} $isMobile={isMobile}>
                                <Header>Just Listed</Header>
                                <Link
                                    href={`/bookings?city=${_.get(
                                        selectedCity,
                                        'name',
                                    )}&new=true&portfolioId=${portfolioId}`}
                                    passHref
                                >
                                    <AnchorTag id="newly_listed_link">View More</AnchorTag>
                                </Link>
                            </HeaderGrid>
                            {renderMobileDetails()}

                            <Grid item lg={10} md={10} sm={12} xs={12}>
                                <MediaGallery
                                    isMobile={isMobile}
                                    isTablet={isTablet}
                                    allImages={_.get(newRoomTypes[0], 'images')}
                                />

                                {renderDesktopDetails()}
                            </Grid>
                        </Grid>
                    </Container>
                </Wrapper>
            )}
        </>
    );
};

export default PortfolioNewlyListed;

PortfolioNewlyListed.propTypes = {
    portfolio: PropTypes.object,
};

const Wrapper = styled.div`
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: ${(props) => props.theme.sectionBackgroundDark};
    margin-bottom: 80px;
`;

const PropertyName = styled.h4`
    color: ${(props) => props.theme.paragraphLight};
`;

const PropertyNameMobile = styled.h5`
    color: ${(props) => props.theme.paragraphLight};
`;

const Header = styled.h4`
    color: ${(props) => props.theme.paragraphLight};
    width: 50%;
    margin-bottom: 30px;
`;

const PropertyDetails = styled.p`
    margin-top: ${({ $isMobile }) => !$isMobile && 10}px;
    color: ${(props) => props.theme.paragraphLight};
    width: ${({ $isMobile }) => ($isMobile ? '100%' : '60%')};
    margin-bottom: ${({ $isMobile }) => $isMobile && 50}px;
`;

const AnchorTag = styled.a`
    color: ${(props) => props.theme.primaryLight};
`;

const HeaderGrid = styled(Grid)`
    display: ${({ $isMobile }) => $isMobile && 'flex'};
    flex-direction: ${({ $isMobile }) => $isMobile && 'row'};
    justify-content: ${({ $isMobile }) => $isMobile && 'space-between'};
`;

const BathtubIcon = styled(Bathtub)`
    color: white;
`;

const KingBedOutlinedIcon = styled(KingBedOutlined)`
    color: white;
`;

const DriveEtaOutlinedIcon = styled(DriveEtaOutlined)`
    color: white;
`;

const StyledNumber = styled.p`
    color: white;
    margin-left: 5px;
    margin-right: 10px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: ${({ $isMobile }) => ($isMobile ? 'flex-end' : 'space-between')};
    width: ${({ $isMobile }) => $isMobile && '100%'};
    margin-bottom: ${({ $isMobile }) => $isMobile && '20px'};
`;
